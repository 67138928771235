.ph {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 100%;
  font-weight: 700;
  font-family: inherit;
}
.slidercontinuous {
  flex: 1;
}
.inputstandard {
  border: 0;
  background-color: transparent;
}
.phParent,
.slidercontinuousParent {
  align-self: stretch;
  overflow: hidden;
  display: flex;
}
.slidercontinuousParent {
  height: 63px;
  flex-shrink: 0;
  flex-direction: row;
  padding: 0 var(--padding-6xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-21xl);
}
.phParent {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  font-family: var(--font-inter);
}
